import { CriterionControl } from '@/Litigation/criterion.entity';

const multipleWords: Record<string, number> = {
    'milliards?': 1e9,
    'millions?': 1e6,
    'mil(?:le)?s?': 1e3,
};

const numberWords: Record<string, number> = {
    'quatre-vingts': 80,
    'quatre vingts': 80,
    soixante: 60,
    cinquante: 50,
    quarante: 40,
    trente: 30,
    vingt: 20,
    'dix-neuf': 19,
    'dix neuf': 19,
    'dix-huit': 18,
    'dix huit': 18,
    'dix-sept': 17,
    'dix sept': 17,
    seize: 16,
    quinze: 15,
    quatorze: 14,
    treize: 13,
    douze: 12,
    onze: 11,
    dix: 10,
    neuf: 9,
    huit: 8,
    sept: 7,
    six: 6,
    cinq: 5,
    quatre: 4,
    trois: 3,
    deux: 2,
    un: 1,
};

const cleanDateWords = (raw: string): string => {
    return raw
        .normalize('NFD')
        .replace(/\p{Pd}/gu, ' ') // Replace all sort of dashes by spaces
        .replace(/\p{Diacritic}/gu, '') // Remove all accents
        .replace(/\s+/g, ' ') // Replace all whitespace chars by spaces
        .replace(/[^a-z ]/gi, '') // Keep chars related to numbers only
        .toLowerCase();
};

const cleanNumbers = (raw: string): string => {
    return raw
        .replace(/[^0-9,\.]/g, '') // Keep chars related to numbers only
        .replace(/[,\.]([0-9]{3})/g, '$1') // Remove commas and dots used as thousands separators
        .replace(/,/g, '.') // Replace decimal separators by dots
        .replace(/^\.+|\.+$/g, ''); // Remove starting and ending dots
};

const wordsToNumbers = (raw: string): number => {
    const multipleSplitRegexp = new RegExp(`(.*?(?:${Object.keys(multipleWords).join('|')}))`);
    const parts = cleanDateWords(raw)
        .split(multipleSplitRegexp)
        .filter((part) => part !== '');

    const partsWithCoefficients: Record<number, string> = {};
    parts.forEach((part) => {
        const tmpWords = part.split(' ');
        const lastWord = tmpWords[tmpWords.length - 1];
        const coefficientKey =
            Object.keys(multipleWords).find((pattern) => {
                return new RegExp(pattern, '').test(lastWord);
            }) || '';
        const coefficient = multipleWords[coefficientKey] || 1;

        partsWithCoefficients[coefficient] = part
            .replace(new RegExp(`${Object.keys(multipleWords).join('|')}$`), '')
            .trim();
    });

    const partsWithWordsReplaced: Record<number, string> = {};
    Object.entries(partsWithCoefficients).forEach(([coefficient, words]) => {
        const regexp = new RegExp(`${Object.keys(numberWords).join('|')}`, 'g');

        partsWithWordsReplaced[Number(coefficient)] = words.replace(regexp, (match) => {
            return String(numberWords[match]);
        });
    });

    const partsComputed: Record<number, number> = {};
    Object.entries(partsWithWordsReplaced).forEach(([coefficient, words]) => {
        let total = 0;
        words.split(' ').forEach((currentWord, i, array) => {
            const nextWord = array.length > i + 1 ? array[i + 1] : '';
            const firstIsCent = /cents?/.test(currentWord) && i === 0;
            const currentIsCent = /cents?/.test(currentWord);
            const nextIsCent = /cents?/.test(nextWord);
            const skipWord = currentIsCent && !firstIsCent;
            if (!skipWord) {
                if (firstIsCent) {
                    total += 100;
                } else if (nextIsCent) {
                    total += Number(currentWord) * 100;
                } else {
                    total += Number.isNaN(Number(currentWord)) ? 0 : Number(currentWord);
                }
            }
        });

        partsComputed[Number(coefficient)] = Number(coefficient) * (total || (Number(coefficient) === 1 ? 0 : 1));
    });

    return Object.values(partsComputed).reduce((a, b) => a + b, 0);
};

export const formatFloat = (raw: string): string => {
    let numbers = parseFloat(cleanNumbers(raw));

    if (Number.isNaN(numbers)) {
        numbers = wordsToNumbers(raw);

        if (Number.isNaN(numbers) || numbers === 0) {
            return '';
        }
    }

    return numbers.toFixed(2);
};

export const formatInteger = (raw: string): string => {
    const numbers = formatFloat(raw);
    if (numbers === '') {
        return '';
    }

    return String(parseInt(numbers, 10));
};

export const formatDate = (raw: string): string => {
    const monthsPatterns: Record<string, string> = {
        'janv(?:ier)?': '01',
        'f[ée]vr(?:ier)?': '02',
        mars: '03',
        'avr(?:il)?': '04',
        mai: '05',
        juin: '06',
        'juill(?:et)?': '07',
        'ao[ûu]t': '08',
        'sept(?:embre)?': '09',
        'oct(?:obre)?': '10',
        'nov(?:embre)?': '11',
        'd[ée]c(?:embre)?': '12',
    };

    const monthSplitRegexp = new RegExp(`(${Object.keys(monthsPatterns).join('|')})`, 'i');
    const parts = raw.split(monthSplitRegexp).filter((part) => part !== '');
    const monthInLetters = parts.length === 3;
    let numbers = '';
    if (monthInLetters) {
        parts[0] = String(wordsToNumbers(parts[0]) || formatInteger(parts[0]));
        Object.entries(monthsPatterns).forEach(([pattern, replacement]) => {
            const regex = new RegExp(pattern, 'gi');
            parts[1] = parts[1].replace(regex, replacement);
        });

        parts[2] = String(wordsToNumbers(parts[2]) || formatInteger(parts[2]));

        numbers = parts.join('/');
    } else {
        numbers = raw
            .replace(/[^0-9/\.\- ]/g, '') // Keep chars related to date only
            .replace(/[\.\- ]+/g, '/') // Replace wrong chars used as date separators by slashes
            .replace(/^\/+|\/+$/g, ''); // Remove starting and ending slashes
    }

    return numbers.replace(/([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{2,4})/, (_match, p1, p2, p3) => {
        return `${p3.length === 2 ? '20' : ''}${p3}${p2.padStart(2, '0')}${p1.padStart(2, '0')}`;
    }); // Homogenize date format
};

export const formatText = (raw: string): string => {
    return raw
        .normalize('NFD')
        .replace(/\p{Pd}/gu, ' ') // Replace all sort of dashes by spaces
        .replace(/\p{Diacritic}/gu, '') // Remove all accents
        .replace(/\s+/g, ' ') // Replace all whitespace chars by spaces
        .toUpperCase();
};

export const formatControl = (raw: string, control: CriterionControl): string => {
    if (raw === '-1') {
        return '-1';
    }

    if (control === 'float') {
        return formatFloat(raw);
    } else if (control === 'integer') {
        return formatInteger(raw);
    } else if (control === 'date') {
        return formatDate(raw);
    } else if (control === 'text') {
        return formatText(raw);
    }

    return raw;
};
