import { Analysis, AnalysisErrorStatuses, AnalysisShort } from '@/Analysis/analysis.entity';
import HttpClient from '@/common/Http';
import { Decision } from '@/Decision/decision.entity';
import { Criterion } from '@/Litigation/criterion.entity';

export default class AnalysisService {
    private httpClient: HttpClient;

    constructor() {
        this.httpClient = new HttpClient('analyses');
    }

    public createAnalysis(decision: Decision, litigationName: string): Promise<AnalysisShort> {
        const uploadFlagHeader = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const { file } = decision;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('litigationName', litigationName);

        return this.httpClient.post<AnalysisShort>('/analyses', formData, uploadFlagHeader);
    }

    public async findAnalysisById(analysisId: string): Promise<Analysis> {
        const analysis = await this.httpClient.get<Analysis>(`/analyses/${analysisId}`);

        analysis.entries = analysis.entries.map((entry) => {
            let formattedValue = entry.value;
            if (typeof formattedValue === 'string') {
                try {
                    const parsedValue = JSON.parse(formattedValue);
                    if (Array.isArray(parsedValue)) {
                        formattedValue = parsedValue;
                    }
                } catch (error) {}
            }

            entry.value = formattedValue;

            return entry;
        });

        return analysis;
    }

    public async findFileAnalysisById(analysisId: string): Promise<Blob> {
        return await this.httpClient.getBlob(`/analyses/${analysisId}/file`);
    }

    public findAll(
        page: number,
        queryString: string,
        filters: Record<string, string[]>,
        sort: string,
    ): Promise<{ meta: Record<string, unknown>; data: Analysis[] }> {
        const params = new URLSearchParams();
        params.append('page', page.toString());
        if (sort) {
            params.append('sort', sort);
        }
        if (filters) {
            Object.keys(filters).forEach((column) => {
                Object.values(filters[column]).forEach((value) => {
                    params.append(`filter[${column}][]`, value);
                });
            });
        }
        if (queryString) {
            params.append('queryString', queryString);
        }

        return this.httpClient.get<{ meta: Record<string, unknown>; data: Analysis[] }>(
            `/analyses?${params.toString()}`,
        );
    }

    public deleteAnalysisById(analysisId: string): Promise<void> {
        return this.httpClient.delete<void>(`/analyses/${analysisId}`);
    }

    public async updateStatus(analysisId: string, status: string): Promise<void> {
        await this.httpClient.put<Analysis>(`/analyses/${analysisId}/status`, { status });
    }

    public async fixError(analysisId: string, criterion: Criterion, occurrence: number): Promise<void> {
        await this.httpClient.put<Analysis>(
            `/analyses/${analysisId}/criteria/${criterion.criterionId}/occurrence/${occurrence}/error-status`,
            { status: AnalysisErrorStatuses.FIXED },
        );
    }

    public async ignoreError(analysisId: string, criterion: Criterion, occurrence: number): Promise<void> {
        await this.httpClient.put<Analysis>(
            `/analyses/${analysisId}/criteria/${criterion.criterionId}/occurrence/${occurrence}/error-status`,
            { status: AnalysisErrorStatuses.IGNORED },
        );
    }
}
