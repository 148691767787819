import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "is-flex-direction-column" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "columns is-vcentered" }
const _hoisted_4 = { class: "column is-12" }
const _hoisted_5 = {
  id: "page-title",
  class: "title is-4 is-uppercase has-text-weight-medium is-inline"
}
const _hoisted_6 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_SetUp = _resolveComponent("SetUp")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_criterion_form = _resolveComponent("criterion-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.litigation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, [
                _createVNode(_component_router_link, {
                  to: { name: 'analysis-list', params: { litigationId: _ctx.litigationId } }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.litigation.name), 1)
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createTextVNode(" / " + _toDisplayString(_ctx.$t('litigationForm.pageTitle')), 1)
              ])
            ])
          ]),
          _createVNode(_component_el_form, {
            model: _ctx.litigation,
            "label-width": "120px",
            onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.debounceSaveLitigation()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: _ctx.t('litigationForm.code'),
                prop: "name",
                required: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.litigation.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.litigation.name) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.t('litigationForm.title'),
                prop: "title",
                required: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.litigation.title,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.litigation.title) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.t('litigationForm.description'),
                prop: "description",
                required: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.litigation.description,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.litigation.description) = $event)),
                    type: "textarea",
                    autosize: { minRows: 3 }
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, null, {
                label: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('litigationForm.criteria')) + " ", 1),
                  _createVNode(_component_el_button, {
                    size: "small",
                    icon: _ctx.Plus,
                    class: "button is-primary is-radius-small new-criterion-button",
                    onClick: _ctx.openCriterionForm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('litigationForm.newCriterion')), 1)
                    ]),
                    _: 1
                  }, 8, ["icon", "onClick"])
                ]),
                default: _withCtx(() => [
                  (_ctx.litigation.criteria.length > 0)
                    ? (_openBlock(), _createBlock(_component_el_tree, {
                        key: 0,
                        data: _ctx.litigation.criteria,
                        props: _ctx.defaultProps,
                        draggable: "",
                        "default-expand-all": "",
                        "node-key": "criterionId",
                        "allow-drop": _ctx.allowDrop,
                        onNodeClick: _ctx.openCriterionForm,
                        onNodeDragEnd: _ctx.handleDragEnd
                      }, {
                        default: _withCtx(({ data }) => [
                          _createVNode(_component_el_button, {
                            size: "small",
                            icon: _ctx.isForemostCriterion(data.criterionId) ? _ctx.StarFilled : _ctx.Star,
                            plain: "",
                            title: "Critère significatif",
                            onClick: _withModifiers(($event: any) => (
                                        _ctx.isForemostCriterion(data.criterionId)
                                            ? _ctx.removeForemostCriterion(data.criterionId)
                                            : _ctx.addForemostCriterion(data.criterionId)
                                    ), ["stop"])
                          }, null, 8, ["icon", "onClick"]),
                          _createTextVNode("  "),
                          _createElementVNode("span", {
                            title: data.title
                          }, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.$t(`criterionForm.${data.type}`)), 1),
                            _createTextVNode(" / " + _toDisplayString(data.section) + " / ", 1),
                            (data.rules.length > 0 || data.combinations.length > 0)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createVNode(_component_el_icon, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_SetUp)
                                    ]),
                                    _: 1
                                  }),
                                  _createTextVNode("  ")
                                ], 64))
                              : _createCommentVNode("", true),
                            _createElementVNode("strong", {
                              class: _normalizeClass({ required: data.required })
                            }, _toDisplayString(data.code), 3)
                          ], 8, _hoisted_6)
                        ]),
                        _: 1
                      }, 8, ["data", "props", "allow-drop", "onNodeClick", "onNodeDragEnd"]))
                    : (_openBlock(), _createBlock(_component_el_empty, { key: 1 })),
                  _createVNode(_component_el_dialog, {
                    modelValue: _ctx.formCriterionVisible,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formCriterionVisible) = $event)),
                    "destroy-on-close": "",
                    fullscreen: true,
                    title: _ctx.formCriterionId ? _ctx.$t('criterionForm.editTitle') : _ctx.$t('criterionForm.createTitle'),
                    "lock-scroll": false
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_criterion_form, {
                        "litigation-id": _ctx.litigationId,
                        criteria: _ctx.litigation.criteria,
                        "criterion-id": _ctx.formCriterionId,
                        onCriterionSaved: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeCriterionForm())),
                        onCriterionSavedAndNew: _cache[4] || (_cache[4] = ($event: any) => (_ctx.closeCriterionFormAndNew()))
                      }, null, 8, ["litigation-id", "criteria", "criterion-id"])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "title"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"])
        ]))
      : _createCommentVNode("", true)
  ]))
}