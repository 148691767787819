<template>
    <footer>
        <a
            :href="`https://www.lexisnexis.com/global/privacy/${$i18n.locale.split('-')[0]}/privacy-policy-lnf.page`"
            target="_blank"
            >{{ $t('common.privacyPolicy') }}</a
        >
    </footer>
</template>

<style scoped lang="scss">
footer {
    position: fixed;
    bottom: 0;
    padding: 10px;
    width: 100%;
    background-color: #fff;

    a {
        font-size: 11px;
        color: #333;
    }
}
</style>
