import { defineStore } from 'pinia';

import { Member } from '@/Member/member.entity';
import MemberService from '@/Member/member.service';

type MemberState = {
    member: Member | null;
};

export const useMemberStore = defineStore('member', {
    state: (): MemberState => ({
        member: null,
    }),
    getters: {
        memberRole(state: MemberState): string | null {
            if (state.member !== null) {
                return String(state.member.role);
            }

            return null;
        },
        isLoggedIn(state: MemberState): boolean {
            return state.member !== null;
        },
    },
    actions: {
        async logIn(credentials: Record<string, string>): Promise<boolean> {
            try {
                const memberService = new MemberService();
                this.member = await memberService.logMemberIn(credentials.email, credentials.password);

                return true;
            } catch (error) {
                return false;
            }
        },
        async logOut() {
            try {
                this.$reset();

                const memberService = new MemberService();
                await memberService.logMemberOut();

                return true;
            } catch (error) {
                return false;
            }
        },
    },
    persist: true,
});
