
import { Plus, SetUp, Star, StarFilled } from '@element-plus/icons-vue';
import { debounce } from 'debounce';
import { ElMessage } from 'element-plus';
import type { NodeDropType } from 'element-plus/es/components/tree/src/tree.type';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import CriterionForm from '@/components/CriterionForm.vue';
import { Criterion } from '@/Litigation/criterion.entity';
import CriterionService from '@/Litigation/criterion.service';
import { Litigation } from '@/Litigation/litigation.entity';
import LitigationService from '@/Litigation/litigation.service';

export default defineComponent({
    components: { CriterionForm, SetUp },
    props: {
        litigationId: {
            type: String,
            required: true,
        },
    },
    setup() {
        const { t } = useI18n();

        return {
            Plus,
            Star,
            StarFilled,
            t,
        };
    },
    data() {
        return {
            litigation: null as Litigation | null,
            defaultProps: {
                children: 'children',
            },
            formCriterionId: null as string | null,
            formCriterionVisible: false,
        };
    },
    async created() {
        this.litigation = await this.getLitigation(this.litigationId);
    },
    methods: {
        async getLitigation(litigationId: string): Promise<Litigation> {
            const litigationService = new LitigationService();
            return litigationService.find(litigationId);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        debounceSaveLitigation: debounce(function debounceSaveLitigation(this: any) {
            this.saveLitigation();
        }, 1000),
        async saveLitigation(): Promise<void> {
            if (!this.litigation) {
                return;
            }

            const litigationService = new LitigationService();

            try {
                await litigationService.update(this.litigationId, this.litigation);
            } catch (error) {
                ElMessage.error(this.$t('common.unknownError'));
            }
        },
        openCriterionForm(clickedNode: PointerEvent | Criterion) {
            this.formCriterionId = clickedNode instanceof PointerEvent ? null : clickedNode.criterionId;
            this.formCriterionVisible = true;
        },
        async closeCriterionForm() {
            this.formCriterionId = null;
            this.formCriterionVisible = false;
            this.litigation = await this.getLitigation(this.litigationId);
        },
        async closeCriterionFormAndNew() {
            await this.closeCriterionForm();

            setTimeout(() => {
                this.formCriterionVisible = true;
            }, 500);
        },
        allowDrop(_draggingNode: Node, _dropNode: Node, dropType: NodeDropType) {
            return dropType !== 'inner';
        },
        handleDragEnd(
            { data: draggingNodeData }: { data: Criterion },
            { data: dropNodeData }: { data: Criterion },
            dropType: 'before' | 'after',
        ) {
            const criterionService = new CriterionService();
            criterionService.move(draggingNodeData.criterionId, dropType, dropNodeData.criterionId);
        },
        async addForemostCriterion(criterionId: string) {
            const criterionService = new CriterionService();
            await criterionService.addForemost(criterionId);
            this.litigation = await this.getLitigation(this.litigationId);
        },
        async removeForemostCriterion(criterionId: string) {
            const criterionService = new CriterionService();
            await criterionService.removeForemost(criterionId);
            this.litigation = await this.getLitigation(this.litigationId);
        },
        isForemostCriterion(criterionId: string): boolean {
            return (this.litigation?.foremostCriteria || []).some(
                (foremostCriterion) => foremostCriterion.criterionId === criterionId,
            );
        },
    },
});
