import { Decision } from '@/Decision/decision.entity';
import { Entry } from '@/Entry/entry.entity';
import { Criterion } from '@/Litigation/criterion.entity';
import { Litigation } from '@/Litigation/litigation.entity';
import { Member } from '@/Member/member.entity';

export enum AnalysisStatus {
    NOT_STARTED = 'NOT_STARTED',
    ONGOING = 'ONGOING',
    CHECKING = 'CHECKING',
    TO_REVIEW = 'TO_REVIEW',
    APPROVED = 'APPROVED',
}

export enum AnalysisErrorStatuses {
    UNPROCESSED = 'UNPROCESSED',
    IGNORED = 'IGNORED',
    FIXED = 'FIXED',
}

export type AnalysisError = {
    criterion: Criterion;
    occurrence: number;
    status: AnalysisErrorStatuses;
    message: string;
};

export type AnalysisShort = {
    analysisId: string;
    createdAt: string;
    status: AnalysisStatus;
    statuses: { status: AnalysisStatus; createdAt: Date }[];
};

export type Analysis = AnalysisShort & {
    decision: Decision;
    litigation: Litigation;
    owner: Member;
    entries: Entry[];
    foremostEntries: Entry[];
    errors: AnalysisError[];
};
