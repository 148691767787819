import HttpClient from '@/common/Http';
import apiConfig from '@/config';
import { Member } from '@/Member/member.entity';

export default class MemberService {
    private httpClient: HttpClient;

    constructor() {
        this.httpClient = new HttpClient('members');
    }

    async getConnectedMember(): Promise<Member> {
        return this.httpClient.get<Member>('/members/me');
    }

    /**
     * Force users to use php api to log in.
     */
    async logMemberIn(email: string, password: string): Promise<Member> {
        return this.httpClient.post<Member>(`${apiConfig.apiUrl}/auth/login`, { email, password });
    }

    async logMemberOut(): Promise<Member> {
        return this.httpClient.post<Member>('/auth/logout');
    }

    async find(memberId: string): Promise<Member> {
        return this.httpClient.get<Member>(`/members/${memberId}`);
    }

    async findAll(): Promise<Member[]> {
        return this.httpClient.get<Member[]>('/members');
    }

    async create(email: string): Promise<Member> {
        return this.httpClient.post<Member>('/members', { email });
    }

    public update(memberId: string, member: Member): Promise<Member> {
        return this.httpClient.put<Member>(`/members/${memberId}`, member);
    }
}
