import { Litigation } from '@/Litigation/litigation.entity';

import { Analysis, AnalysisErrorStatuses, AnalysisStatus } from '../Analysis/analysis.entity';
import { Member, MemberRole } from '../Member/member.entity';

type subjects = Member | 'member' | Litigation | 'litigation' | Analysis | 'analysis';

const isMember = (subject: subjects): subject is Member => {
    return (subject as Member).memberId !== undefined;
};

const isLitigation = (subject: subjects): subject is Litigation => {
    return (subject as Litigation).litigationId !== undefined;
};

const isAnalysis = (subject: subjects): subject is Analysis => {
    return (subject as Analysis).analysisId !== undefined;
};

export const isAllowed = (member: Member, action: string, subject: subjects): boolean => {
    if (!member.role) {
        return false;
    }

    if (isMember(subject) || subject === 'member') {
        if (['list', 'read'].includes(action)) {
            return true;
        } else if (['create', 'edit_user'].includes(action)) {
            return (
                [MemberRole.ADMIN, MemberRole.SUPERVISOR].includes(member.role) ||
                (subject !== 'member' && member.memberId === subject.memberId)
            );
        } else if (['edit_supervisor', 'edit_admin'].includes(action)) {
            return member.role === MemberRole.ADMIN || (subject !== 'member' && member.memberId === subject.memberId);
        }
    } else if (isLitigation(subject) || subject === 'litigation') {
        if (action === 'create') {
            return [MemberRole.ADMIN, MemberRole.SUPERVISOR].includes(member.role);
        } else if (['edit', 'export'].includes(action)) {
            return (
                member.role === MemberRole.ADMIN ||
                (member.role === MemberRole.SUPERVISOR &&
                    isLitigation(subject) &&
                    (member.litigations || []).some((litigation) => litigation.litigationId === subject.litigationId))
            );
        }
    } else if (isAnalysis(subject)) {
        const hasErrors =
            subject.errors && subject.errors.filter((v) => v.status === AnalysisErrorStatuses.UNPROCESSED).length > 0;
        const ownAnalysis = subject.owner.memberId === member.memberId;
        if (member.role === MemberRole.USER && !ownAnalysis) {
            return false;
        }

        if (action === 'check') {
            const allowedStatuses = [AnalysisStatus.NOT_STARTED, AnalysisStatus.ONGOING];

            return (
                allowedStatuses.includes(subject.status) || (subject.status === AnalysisStatus.CHECKING && hasErrors)
            );
        } else if (action === 'flagForReview') {
            return member.role === MemberRole.USER && subject.status === AnalysisStatus.CHECKING && !hasErrors;
        } else if (action === 'approve') {
            if ([AnalysisStatus.CHECKING, AnalysisStatus.TO_REVIEW].includes(subject.status) && !hasErrors) {
                if (member.role === MemberRole.ADMIN) {
                    return true;
                } else if (member.role === MemberRole.SUPERVISOR) {
                    return (member.litigations || []).some(
                        (litigation) => litigation.litigationId === subject.litigation.litigationId,
                    );
                }
            }
        } else if (['view', 'edit', 'delete'].includes(action)) {
            if (member.role === MemberRole.ADMIN) {
                return true;
            } else if (member.role === MemberRole.SUPERVISOR) {
                return (member.litigations || []).some(
                    (litigation) => litigation.litigationId === subject.litigation.litigationId,
                );
            } else {
                const allowedStatuses = [AnalysisStatus.NOT_STARTED, AnalysisStatus.ONGOING, AnalysisStatus.CHECKING];

                return allowedStatuses.includes(subject.status);
            }
        }
    }

    return false;
};
