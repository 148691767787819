
import { ElMessage } from 'element-plus';
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import customFooter from '@/components/Footer.vue';
import { useMemberStore } from '@/stores/member';

export default defineComponent({
    name: 'Login',
    components: { customFooter },
    setup() {
        return {
            memberStore: useMemberStore(),
            router: useRouter(),
            route: useRoute(),
        };
    },
    data() {
        return {
            email: '',
            password: '',
        };
    },
    methods: {
        async login(): Promise<void> {
            const success = await this.memberStore.logIn({
                email: this.email,
                password: this.password,
            });

            if (success) {
                this.router.push(this.route.query.redirect?.toString() || { name: 'litigation-list' });
            } else {
                ElMessage.error(this.$t('login.errors.wrongCredentials'));
            }
        },
    },
});
