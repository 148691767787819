import 'element-plus/theme-chalk/index.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAngleDown,
    faArrowCircleLeft,
    faCheckCircle,
    faCogs,
    faDownload,
    faEdit,
    faEnvelope,
    faFileImport,
    faFileUpload,
    faHighlighter,
    faLock,
    faPlusCircle,
    faQuestionCircle,
    faSearch,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ElementPlus from 'element-plus';
import frLocale from 'element-plus/es/locale/lang/fr';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

import App from './App.vue';
import appTranslations from './i18n/global';
import router from './router';

const i18n = createI18n({
    fallbackLocale: 'en',
    messages: appTranslations,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    locale: navigator.language,
    legacy: false,
});

library.add({
    faAngleDown,
    faArrowCircleLeft,
    faCheckCircle,
    faCogs,
    faDownload,
    faEdit,
    faEnvelope,
    faFileImport,
    faFileUpload,
    faHighlighter,
    faLock,
    faQuestionCircle,
    faSearch,
    faSpinner,
    faPlusCircle,
});

const app = createApp(App);

/**
 * Global filters
 */
app.config.globalProperties.$filters = {
    toLocaleDateString(date: string): string {
        return new Date(date).toLocaleString();
    },
};

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.component('FontAwesomeIcon', FontAwesomeIcon)
    .use(pinia)
    .use(router)
    .use(i18n)
    .use(ElementPlus, {
        locale: frLocale,
    })
    .mount('#app');
