import { defineStore } from 'pinia';

type AnalysisState = {
    sort: Record<
        string,
        {
            prop: string;
            order: 'ascending' | 'descending';
        }
    >;
    filters: Record<string, Record<string, string[]>>;
    recent: Array<string>;
};

export const useAnalysisStore = defineStore('analysis', {
    state: (): AnalysisState => ({
        sort: {},
        filters: {},
        recent: [],
    }),
    actions: {
        getSort(module: string) {
            return this.sort.hasOwnProperty(module) ? this.sort[module] : null;
        },
        setSort(module: string, prop: string | null, order: 'ascending' | 'descending') {
            if (prop === null) {
                delete this.sort[module];
            } else {
                this.sort[module] = { prop, order };
            }
        },
        getFilters(module: string) {
            return this.filters.hasOwnProperty(module) ? this.filters[module] : {};
        },
        addFilter(module: string, filters: Record<string, string[]>) {
            this.filters[module] = { ...this.filters[module], ...filters };
        },
        isRecent(analysisId: string): boolean {
            return this.recent.includes(analysisId);
        },
        markAsRecent(analysisId: string) {
            this.recent.push(analysisId);
        },
        markAsViewed(analysisId: string) {
            const index = this.recent.findIndex((value: string) => value === analysisId);
            if (index !== -1) {
                delete this.recent[index];
            }
        },
    },
    persist: true,
});
