/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    AxiosResponseHeaders,
    InternalAxiosRequestConfig,
    RawAxiosResponseHeaders,
} from 'axios';
import sha256 from 'crypto-js/sha256';
import JSONAPISerializer from 'json-api-serializer';
import { storeToRefs } from 'pinia';

import apiConfig from '@/config';
import { Member } from '@/Member/member.entity';
import { useLoaderStore } from '@/stores/loader';
import { useMemberStore } from '@/stores/member';

declare module 'axios' {
    export interface AxiosRequestConfig {
        showLoader?: boolean;
    }
    export interface AxiosResponse<T = any, D = any> {
        data: T;
        status: number;
        statusText: string;
        headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
        config: InternalAxiosRequestConfig<D>;
        request?: any;
        meta?: any;
    }
}

class Http {
    private http: AxiosInstance;

    private deserializer: JSONAPISerializer;

    private member: Member | null = null;

    constructor(typeToDeserialize?: string) {
        const loaderStore = useLoaderStore();
        const memberStore = useMemberStore();
        this.member = storeToRefs(memberStore)?.member?.value;

        this.http = axios.create({
            baseURL: this.loadBaseUrlPerUser(typeToDeserialize),
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            showLoader: true,
            timeout: 1000 * 60 * 10,
        });

        this.deserializer = new JSONAPISerializer();
        this.deserializer.register('members', {
            id: 'memberId',
        });

        this.deserializer.register('litigations', {
            id: 'litigationId',
            relationships: {
                criteria: {
                    type: 'criteria',
                },
            },
        });

        this.deserializer.register('decisions', {
            id: 'decisionId',
        });

        this.deserializer.register('analyses', {
            id: 'analysisId',
        });

        this.deserializer.register('criteria', {
            id: 'criterionId',
            relationships: {
                entries: {
                    type: 'entries',
                },
            },
        });

        this.deserializer.register('entries', {
            id: 'entryId',
            relationships: {
                criteria: {
                    type: 'criteria',
                },
            },
        });

        this.deserializer.register('rules');

        this.http.interceptors.request.use(
            (config) => {
                if (config.showLoader) {
                    loaderStore.pending();
                }
                return config;
            },
            (error) => {
                if (error.config.showLoader) {
                    loaderStore.done();
                }
                return Promise.reject(error);
            },
        );

        this.http.interceptors.response.use(
            (response) => {
                if (response.config.showLoader) {
                    loaderStore.done();
                }

                if (!typeToDeserialize) {
                    return response;
                }

                const meta = Object.keys(response.data).includes('meta') ? { meta: response.data.meta } : null;

                return {
                    ...response,
                    ...meta,
                    ...{ data: this.deserializer.deserialize(typeToDeserialize, response.data) },
                };
            },
            (error) => {
                const { response } = error;

                if (response.status === 401) {
                    memberStore.logOut();
                }

                if (response.config.showLoader) {
                    loaderStore.done();
                }

                return Promise.reject(error);
            },
        );
    }

    public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response: AxiosResponse = await this.http.get(url, config);

        if (response.meta) {
            return { meta: response.meta, data: response.data } as any;
        }

        return response.data;
    }

    public async getBlob(url: string, config?: AxiosRequestConfig): Promise<Blob> {
        const response = await this.http.get(url, { ...config, ...{ responseType: 'blob' } });

        return response.request.response;
    }

    public async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response: AxiosResponse = await this.http.post(url, data, config);
        return response.data;
    }

    public async put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response: AxiosResponse = await this.http.put(url, data, config);
        return response.data;
    }

    public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response: AxiosResponse = await this.http.delete(url, config);
        return response.data;
    }

    private loadBaseUrlPerUser(typeToDeserialize: string = ''): string {
        const currentUserHash = sha256(this.member?.email || '').toString();
        const hashAllowedToUseNewApi = [
            'f15db29fa4a3c7d5852f5c46b6f857c9177798dfcaafae3255a62a0c344f1b50', // ab,
            '2f32983898abab6580474b2e28293869944a6c03f0a41c74c3c03b518d06ccd8', // cl,
            '02132470a9fe6185ac0dff728b187487492aaf87d5e8313cf051238a6d5222fb', // ec,
            'bcc13be6e6fab914b47b4e93ad7be5e2b1a8fa8b29c164b563e2b723b359e738', // hg,
            'c493153d65200075b24048d2eee6da4718af7e1707e868fdca291012c23e60ab', // loib,
            'cd5551d803fc1bb41246acb15273d2babc09322cb02c99beb530e884218c3ce5', // md,
            'c4961aa9c7da2992304b5d991c0b21e6435a2ab9fead2ceb0355282412ecc55a', // md + ana,
            'fa8a8a578db4994207e7e1e215ac38854a14029190b0e7294fe28c5ce56a52e2', // yc
            'ef7ca461a43eb3d9846421c91d697c05097e9d578faa34f0b008d75f99a5690a', // qg
        ];
        const newApiSupportedType = ['members', 'criteria', 'rules', 'decisions', 'litigations', 'analyses'];

        if (hashAllowedToUseNewApi.includes(currentUserHash) && newApiSupportedType.includes(typeToDeserialize)) {
            return apiConfig.apiUrl;
        }

        return apiConfig.apiLegacyUrl;
    }
}

export default Http;
