import HttpClient from '@/common/Http';

import { Rule } from './rule.entity';

export default class RuleService {
    private httpClient: HttpClient;

    constructor() {
        this.httpClient = new HttpClient('rules');
    }

    async findAll(): Promise<Rule[]> {
        return await this.httpClient.get<Rule[]>('/rules');
    }
}
