import Mark from 'mark.js';

import { Entry } from '@/Entry/entry.entity';
import { Criterion } from '@/Litigation/criterion.entity';

type HighlightRange = { criterion: Criterion; occurrence: number; start: number; end: number; length?: number };

const getAllMarks = (): Record<string, Element> => {
    return Array.from(document.querySelectorAll('mark[data-code]'))
        .filter((mark) => !mark.classList.contains('empty'))
        .sort((firstElement: Element, secondElement: Element) => {
            const firstCriterionStart = parseInt((firstElement as HTMLElement).dataset.start || '', 10);
            const secondCriterionStart = parseInt((secondElement as HTMLElement).dataset.start || '', 10);
            return firstCriterionStart - secondCriterionStart;
        })
        .reduce(
            (accumulator, mark) => {
                const { dataset } = mark as HTMLElement;
                const occurrence = dataset.occurrence || '';
                const code = (dataset.code || '').replace(/_i($|_)/, `_${Number(occurrence)}$1`);

                if (code && !(code in accumulator)) {
                    // eslint-disable-next-line no-param-reassign
                    accumulator[code] = mark;
                }

                return accumulator;
            },
            {} as Record<string, Element>,
        );
};

const clearAllTooltips = () => {
    document.querySelectorAll('.decision-tooltips > div').forEach((tooltip) => tooltip.remove());
};

const computeTooltipPosition = (mark: HTMLElement): { top: number; left: number } => {
    const decisionContent = document.getElementsByClassName('decision-content')[0];
    const rectDecisionContent = decisionContent.getBoundingClientRect();
    const rectMark = mark.getBoundingClientRect();
    const top = rectMark.top - rectDecisionContent.top + decisionContent.scrollTop - 20;
    const left = rectMark.left - rectDecisionContent.left + decisionContent.scrollLeft;
    return { top, left };
};

const createTooltip = (
    mark: HTMLElement,
    code: string,
    occurrence: number,
    text: string,
    positionedTooltips: HTMLElement[],
): HTMLElement => {
    const decisionTooltips = document.getElementsByClassName('decision-tooltips')[0];

    const tooltip = document.createElement('div');
    tooltip.classList.add('tooltip');
    tooltip.dataset.code = code;
    tooltip.dataset.occurrence = occurrence.toString();

    const position = computeTooltipPosition(mark);
    tooltip.style.top = `${position.top}px`;
    tooltip.style.left = `${position.left}px`;
    const textNode = document.createTextNode(text);
    tooltip.appendChild(textNode);
    decisionTooltips.appendChild(tooltip);

    const haveOverlap = (firstElement: HTMLElement, secondElement: HTMLElement) => {
        const firstRect = firstElement.getBoundingClientRect();
        const secondRect = secondElement.getBoundingClientRect();

        return !(
            firstRect.right < secondRect.left ||
            firstRect.left > secondRect.right ||
            firstRect.bottom < secondRect.top ||
            firstRect.top > secondRect.bottom
        );
    };

    const maxIterations = 10;
    let i = 0;
    while (
        i < maxIterations &&
        positionedTooltips.some((positionedTooltip) => haveOverlap(tooltip, positionedTooltip))
    ) {
        i += 1;
        tooltip.style.top = `${parseInt(tooltip.style.top, 10) - 10}px`;
    }

    return tooltip;
};

const activateSelection = (code: string, occurrence: number) => {
    document.querySelectorAll(`mark[data-code="${code}"][data-occurrence="${occurrence}"]`).forEach((mark) => {
        mark.classList.add('mark-hover');
    });
    document.querySelectorAll(`div[data-code="${code}"][data-occurrence="${occurrence}"]`).forEach((tooltip) => {
        tooltip.classList.add('tooltip-hover');
    });
};

const deactivateSelection = (code: string, occurrence: number) => {
    document.querySelectorAll(`mark[data-code="${code}"][data-occurrence="${occurrence}"]`).forEach((mark) => {
        mark.classList.remove('mark-hover');
    });
    document.querySelectorAll(`div[data-code="${code}"][data-occurrence="${occurrence}"]`).forEach((tooltip) => {
        tooltip.classList.remove('tooltip-hover');
    });
};

const clearHighlightedTexts = () => {
    clearAllTooltips();
};

const refreshTooltips = () => {
    const marks = getAllMarks();

    clearAllTooltips();

    const positionedTooltips: HTMLElement[] = [];
    Object.values(marks).forEach((mark) => {
        const { dataset } = mark as HTMLElement;
        const code = dataset.code || '';
        const occurrence = Number(dataset.occurrence || 1);
        const text = code.replace(/_i(_?)/, `_${occurrence}$1`);
        const positionedTooltip = createTooltip(
            mark as HTMLElement,
            code,
            occurrence,
            text,
            positionedTooltips as HTMLElement[],
        );
        if (positionedTooltip) {
            positionedTooltips.push(positionedTooltip);
        }
    });

    const layers = document.querySelectorAll('.decision-highlight-layer');
    layers.forEach((layer) => {
        layer.addEventListener(
            'mouseover',
            (event) => {
                const { dataset } = event.target as HTMLElement;
                activateSelection(dataset.code || '', Number(dataset.occurrence || 1));
            },
            true,
        );
        layer.addEventListener(
            'mouseout',
            (event) => {
                const { dataset } = event.target as HTMLElement;
                deactivateSelection(dataset.code || '', Number(dataset.occurrence || 1));
            },
            true,
        );
    });

    const tooltips = document.querySelector('.decision-tooltips');
    if (tooltips) {
        tooltips.addEventListener(
            'mouseover',
            (event) => {
                const { dataset } = event.target as HTMLElement;
                activateSelection(dataset.code || '', Number(dataset.occurrence || 1));
            },
            true,
        );
        tooltips.addEventListener(
            'mouseout',
            (event) => {
                const { dataset } = event.target as HTMLElement;
                deactivateSelection(dataset.code || '', Number(dataset.occurrence || 1));
            },
            true,
        );
    }
};

const highlightTexts = async (entries: Entry[], onNewLayer: (highlightsLayers: number) => void) => {
    const ranges: HighlightRange[] = entries
        .map((entry) => {
            // eslint-disable-next-line no-param-reassign
            entry.selections = entry.selections.filter(
                (selection) =>
                    selection.startAt !== null && selection.endAt !== null && selection.startAt !== selection.endAt,
            );
            return entry;
        })
        .filter((entry) => entry.selections.length > 0)
        .map((entry) =>
            entry.selections.map((selection) => ({
                criterion: entry.criterion,
                occurrence: entry.occurrence,
                start: selection.startAt || 0,
                length: (selection.endAt || 0) - (selection.startAt || 0),
                end: selection.endAt || 0,
            })),
        )
        .flat();

    const layeredRanges: HighlightRange[][] = [];
    const haveOverlap = (firstRange: HighlightRange, secondRange: HighlightRange) => {
        return (
            (secondRange.end >= firstRange.start && secondRange.end <= firstRange.end) ||
            (secondRange.start >= firstRange.start && secondRange.start <= firstRange.end)
        );
    };

    ranges.forEach((toPositionRange) => {
        const availableLayerIndex = layeredRanges.findIndex((layeredRange) => {
            return layeredRange.every((range) => {
                return !haveOverlap(toPositionRange, range);
            });
        });

        if (availableLayerIndex !== -1) {
            layeredRanges[availableLayerIndex].push(toPositionRange);
        } else {
            layeredRanges.push([toPositionRange]);
        }
    });

    await onNewLayer(layeredRanges.length);

    layeredRanges.forEach((ranges, i) => {
        const context = document.querySelectorAll('.decision-highlight-layer')[i];
        const mark = new Mark(context);

        ranges.forEach((range) => {
            mark.markRanges([{ start: range.start, length: range.end - range.start }], {
                each: (element: HTMLElement, markRange: { start: number; length: number }) => {
                    const matchingElement = ranges.find((checkRange) => {
                        return (
                            checkRange.start === markRange.start &&
                            checkRange.length === markRange.length &&
                            checkRange.criterion.code === range.criterion.code &&
                            checkRange.occurrence === range.occurrence
                        );
                    });

                    if (matchingElement) {
                        if (element.textContent && element.textContent.trim() === '') {
                            element.classList.add('empty');
                        }

                        // eslint-disable-next-line no-param-reassign
                        element.dataset.code = matchingElement.criterion.code;
                        // eslint-disable-next-line no-param-reassign
                        element.dataset.occurrence = range.occurrence.toString();
                        // eslint-disable-next-line no-param-reassign
                        element.dataset.start = range.start.toString();
                        // eslint-disable-next-line no-param-reassign
                        element.dataset.end = range.end.toString();
                    }
                },
            });
        });
    });

    refreshTooltips();
};

export {
    clearHighlightedTexts,
    highlightTexts,
    computeTooltipPosition,
    activateSelection,
    deactivateSelection,
    refreshTooltips,
};
