import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/logo_white.svg'


const _hoisted_1 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: _imports_0,
    alt: _ctx.alt
  }, null, 8, _hoisted_1))
}