export type AppLocale = 'en' | 'fr';

const appTranslations: Record<AppLocale, Record<string, any>> = {
    en: {
        common: {
            decision: 'Decision',
            undefined: 'Undefined',
            unknownError: 'An unknown error occured',
            permissionError: 'Permission denied',
            back: 'Back',
            yes: 'Yes',
            no: 'No',
            empty: 'Empty',
            confirmDialogTitle: 'Confirm',
            confirmDeletion: 'Confirm deletion?',
            deletionError: 'Error during deletion',
            deletionSuccessful: 'Deletion successful',
            edit: 'Edit',
            privacyPolicy: 'Privacy policy',
        },
        status: {
            NOT_STARTED: 'Not started',
            ONGOING: 'Ongoing',
            TO_REVIEW: 'To review',
            CHECKING: 'Checking',
            APPROVED: 'Approved',
        },
        login: {
            email: 'Email',
            password: 'Password',
            login: 'Log in',
            forgotPassword: 'Forgot password?',
            errors: {
                missingCredentials: 'Please fill in both fields',
                wrongCredentials: 'Your email/password combination is incorrect',
            },
        },
        header: {
            administration: 'Administration',
            logout: 'Log out',
            home: 'Home',
            litigations: 'Litigations',
        },
        listAnalyses: {
            analyses: 'Analyses',
            decision: '@:common.decision',
            uploadDate: 'Added on',
            analyzedBy: 'Analyzed by',
            status: 'Status',
            addDecision: 'Add decision',
            exportAnalyses: 'Export analyses',
            export: 'Export',
            exportCentralization: 'Export centralization',
            exportMonotony: 'Export monotony',
            fileName: 'File name',
            foremostCriteria: 'Foremost criteria',
            week: '{week} week',
            search: 'Search...',
        },
        addDecision: {
            decisionAddition: 'Decision addition',
            noFile: 'No file was provided',
            success: 'Decision uploaded',
        },
        analysis: {
            decision: '@:common.decision',
            criteria: 'Criteria',
            check: 'Check',
            checkError: 'Please fix errors in your analysis',
            checkSuccess: 'Your analysis is now complete',
            flagForReview: 'Flag for review',
            flagForReviewSuccess: 'Your analysis will be reviewed by your supervisor',
            approve: 'Approve',
            approveSuccess: 'Analysis approved',
            contextSelectionButtonOn: 'Highlight text',
            contextSelectionButtonOff: 'Deactivate highlighter',
            tooltipSelectionButtonOn: 'Activate tooltips',
            tooltipSelectionButtonOff: 'Deactivate tooltips',
            deleteAnalysis: 'Delete analysis',
            deleteConfirmTitle: 'Warning',
            deleteConfirmMessage: 'Confirm deletion?',
            deleteConfirmed: 'Analysis deleted',
            deleteCanceled: 'Deletion canceled',
            annotate: 'Annotate',
            rawTextTab: 'Raw text',
            pdfTab: 'PDF',
            criteriaTab: 'Criteria',
            statusesTab: 'History',
            fixAction: 'Mark as fixed',
            ignoreAction: 'Ignore error',
        },
        listMembers: {
            members: 'Members',
            addMember: 'Add member',
            cancel: 'Cancel',
            create: 'Create',
            email: 'Email',
            name: 'Name',
            firstName: 'First name',
            lastName: 'Last name',
            isEnabled: 'Enabled',
            litigations: 'Litigations',
            role: 'Role',
            roles: {
                user: 'Analyst',
                supervisor: 'Supervisor',
                admin: 'Administrator',
            },
            memberCreated: 'An email has been sent to the newly created member',
        },
        listLitigations: {
            litigations: 'Litigations',
            code: 'Code',
            addLitigation: 'Add litigation',
            cancel: 'Cancel',
            create: 'Create',
        },
        litigationForm: {
            pageTitle: 'Edit',
            save: 'Save',
            newCriterion: 'New',
            code: 'Code',
            title: 'Title',
            description: 'Description',
            criteria: 'Criteria',
        },
        criterionForm: {
            createTitle: 'New',
            editTitle: 'Edit',
            general: 'General',
            type: 'Type',
            input: 'Input',
            output: 'Output',
            none: 'None',
            code: 'Code',
            codeHelp: `- Allowed characters: lowercase letters, numbers and "_" as word separator<br>
            - Starts with a letter<br>
            - 3 characters minimum<br>
            <br>
            If this criterion appears several times in this module (as in MPT or LITIDESIGN), you can indicate the number of repetitions here`,
            label: 'Label',
            warningLength: 'Pay attention to the length of the label',
            control: 'Control',
            warningDate:
                "If you don't enter a max date, then the max date will be the current date at the time of analysis.",
            minimum: 'Minimum',
            maximum: 'Maximum',
            switch: 'Yes / No',
            dropdownSingle: 'Single dropdown list',
            dropdownMultiple: 'Multiple dropdown list',
            range: 'Range',
            integer: 'Integer',
            float: 'Float',
            date: 'Date',
            text: 'Text',
            url: 'URL',
            unit: 'Unit',
            days: 'days',
            months: 'months',
            years: 'years',
            choices: 'Choices',
            warningChoices: `The -1 choice is implicit, so there's no need to enter it here; it will be added automatically to the analysis page.<br />
            <br />
            Choices will be automatically sorted in ascending code order when saved.<br />
            <br />
            Example:<br />
            <pre>
1: Choice 1
2: Choice 2</pre>`,
            section: 'Section',
            warningSection: `If you want the sections to appear in a particular order, you can prefix them with a letter.<br />
            Example:<br />
            <pre>A - General</pre>`,
            help: 'Help',
            required: 'Required',
            rules: 'Rules',
            errors: {
                required: 'The criterion #criterion# is required',
                range: 'The criterion #criterion# must be between #min# and #max#',
                list: 'The criterion #criterion# must belong to #choices#',
                url: 'The criterion #criterion# contains an invalid URL',
                combinations: 'The criterion #criterion# does not respect the authorized #combinations# combinations',
                from: 'from',
                to: 'to',
                or: 'or',
                rules: {
                    si_contient_alors_different:
                        'If the criterion #criterion# contains #value#, then the criterion #criterion# must be different than #value#',
                    si_contient_alors_egal:
                        'If the criterion #criterion# contains #value#, then the criterion #criterion# must be equal to #value#',
                    si_contient_alors_rempli:
                        'If the criterion #criterion# contains #value#, then the criterion #criterion# must be filled',
                    si_different_alors_contient:
                        'If the criterion #criterion# is different than #value#, then the criterion #criterion# must contain #value#',
                    si_different_alors_egal:
                        'If the criterion #criterion# is different than #value#, then the criterion #criterion# must be equal to #value#',
                    si_egal_alors_contient:
                        'If the criterion #criterion# equals to #value#, then the criterion #criterion# must contain #value#',
                    si_egal_alors_different:
                        'If the criterion #criterion# equals to #value#, then the criterion #criterion# must be different than #value#',
                    si_egal_alors_egal:
                        'If the criterion #criterion# equals to #value#, then the criterion #criterion# must be equal to #value#',
                    si_egal_alors_rempli:
                        'If the criterion #criterion# equals to #value#, then the criterion #criterion# must be filled',
                    si_egal_alors_vide:
                        'If the criterion #criterion# equals to #value#, then the criterion #criterion# must be empty',
                    si_rempli_alors_rempli:
                        'If the criterion #criterion# is filled, then the criterion #criterion# must be filled',
                    si_rempli_alors_superieur:
                        'If the criterion #criterion# is filled, then the criterion #criterion# must be superior',
                    si_vide_alors_rempli:
                        'If the criterion #criterion# is empty, then the criterion #criterion# must be filled',
                },
            },
            newRule: 'New rule',
            ruleExample: `Example:<br />
            <pre>0;3</pre>
            If more than one value, separate them with semicolons.`,
            combinations: 'Combinations',
            combinationsWarning: 'At least one of the following combinations must be respected',
            orOnly: 'or only',
            combinationExemple: `Example:<br />
            <pre>0;3</pre>
            If more than one value, separate them with semicolons.`,
            minValue: 'Min value',
            maxValue: 'Max value',
            newCombination: 'New combination',
            litigation: 'Litigation',
            jpControl: 'JP control',
            example: 'Example data',
            quickEdit: 'Quick edit',
            aiConfig: 'AI config',
            noiseIntensity: 'Noise intensity',
            lowerThreshold: 'Lower threshold',
            upperThreshold: 'Upper threshold',
            monotony: 'Monotony',
            monotoneAugmentationAmplitude: 'Amplitude',
            monotoneAugmentationAmplitudeHelp: `Maximum increase or decrease in criterion value :<br>
                in absolute value if it is to be an integer<br>
                or relatively if it is to be a percentage<br>
                (e.g. IPLSCRS: the number of employees can be increased by 10% (i.e. amplitude = 0.10) when generating decisions, while the age can be increased by 2 (i.e. amplitude = 2.00) when generating decisions)`,
            monotoneAugmentationMin: 'Min',
            monotoneAugmentationMinHelp: 'Minimum value for decision generation',
            monotoneAugmentationMax: 'Max',
            monotoneAugmentationMaxHelp: 'Maximum value for decision generation',
            notConsidered: 'Not considered',
            descending: 'Descending',
            neutral: 'Neutral',
            ascending: 'Ascending',
            request: 'Request',
            proposal: 'Proposal',
            submit: 'Save',
            submitAndNew: 'Save and create a new one',
            invalidFormat: 'Invalid format',
        },
    },
    fr: {
        common: {
            decision: 'Décision',
            undefined: 'Non défini',
            unknownError: 'Une erreur est survenue',
            permissionError: 'Action non autorisée',
            back: 'Retour',
            yes: 'Oui',
            no: 'Non',
            empty: 'Vide',
            confirmDialogTitle: 'Confirmation',
            confirmDeletion: 'Confirmer la suppression ?',
            deletionError: 'Erreur lors de la suppression',
            deletionSuccessful: 'Suppression effectuée',
            edit: 'Editer',
            privacyPolicy: 'Politique de confidentialité et données personnelles',
        },
        status: {
            NOT_STARTED: 'Pas commencée',
            ONGOING: 'En cours',
            TO_REVIEW: 'À revoir',
            CHECKING: 'En vérification',
            APPROVED: 'Approuvée',
        },
        login: {
            email: 'Email',
            password: 'Mot de passe',
            login: 'Se connecter',
            forgotPassword: 'Mot de passe oublié ?',
            errors: {
                missingCredentials: 'Merci de remplir les deux champs',
                wrongCredentials: "L'email ou le mot de passe sont incorrects",
            },
        },
        header: {
            administration: 'Administration',
            logout: 'Se déconnecter',
            home: 'Accueil',
            litigations: 'Contentieux',
        },
        listAnalyses: {
            analyses: 'Analyses',
            decision: '@:common.decision',
            uploadDate: "Date d'ajout",
            analyzedBy: 'Analysée par',
            status: 'Statut',
            addDecision: 'Ajouter décision',
            exportAnalyses: 'Exporter les analyses',
            export: 'Exporter',
            exportCentralization: 'Exporter centralisation',
            exportMonotony: 'Exporter monotonie',
            fileName: 'Nom du fichier',
            foremostCriteria: 'Critères significatifs',
            week: 'Semaine du {week}',
            search: 'Rechercher...',
        },
        addDecision: {
            decisionAddition: 'Ajout de décision',
            noFile: "Aucun fichier n'a été fourni",
            success: 'Décision envoyée',
        },
        analysis: {
            decision: '@:common.decision',
            criteria: 'Critères',
            check: 'Vérifier',
            checkError: 'Merci de corriger les erreurs présentes dans votre analyse',
            checkSuccess: 'Votre analyse est terminée',
            flagForReview: 'Marquer comme "à revoir"',
            flagForReviewSuccess: 'Votre analyse va être revue par votre superviseur',
            approve: 'Approuver',
            approveSuccess: 'Analyse approuvée',
            contextSelectionButtonOn: 'Surligner du texte',
            contextSelectionButtonOff: 'Désactiver le surligneur',
            tooltipSelectionButtonOn: 'Activer les infobulles',
            tooltipSelectionButtonOff: 'Désactiver les infobulles',
            deleteAnalysis: "Supprimer l'analyse",
            deleteConfirmTitle: 'Attention',
            deleteConfirmMessage: 'Confirmer la suppression ?',
            deleteConfirmed: 'Analyse supprimée',
            deleteCanceled: 'Suppression annulée',
            annotate: 'Annoter',
            rawTextTab: 'Texte brut',
            pdfTab: 'PDF',
            criteriaTab: 'Critères',
            statusesTab: 'Historique',
            fixAction: 'Marquer comme corrigé',
            ignoreAction: "Ignorer l'erreur",
        },
        listMembers: {
            members: 'Utilisateurs',
            addMember: 'Ajouter utilisateur',
            cancel: 'Annuler',
            create: 'Créer',
            email: 'Email',
            name: 'Nom',
            firstName: 'Prénom',
            lastName: 'Nom',
            isEnabled: 'Actif',
            litigations: 'Contentieux',
            role: 'Rôle',
            roles: {
                user: 'Analyste',
                supervisor: 'Superviseur',
                admin: 'Administrateur',
            },
            memberCreated: 'Un email a été envoyé au membre nouvellement créé',
        },
        listLitigations: {
            litigations: 'Contentieux',
            code: 'Code',
            addLitigation: 'Ajouter contentieux',
            cancel: 'Annuler',
            create: 'Créer',
        },
        litigationForm: {
            pageTitle: 'Edition',
            save: 'Enregistrer',
            newCriterion: 'Nouveau',
            code: 'Code',
            title: 'Titre',
            description: 'Description',
            criteria: 'Critères',
        },
        criterionForm: {
            createTitle: 'Nouveau',
            editTitle: 'Edition',
            general: 'Général',
            type: 'Type',
            input: 'Entrée',
            output: 'Sortie',
            none: 'Aucun des deux',
            code: 'Code',
            codeHelp: `- Caractères autorisés : lettres minuscules, chiffres et "_" comme séparateur de mot<br>
            - Commence par une lettre<br>
            - 3 caractères minimum<br>
            <br>
            Si ce critère est présent plusieurs fois dans ce module (c'est le cas des postes sur MPT ou des modèles sur LITIDESIGN), vous pouvez indiquer ici le nombre de répétitions`,
            label: 'Libellé',
            warningLength: 'Attention à la longueur du libellé',
            control: 'Contrôle',
            warningDate:
                "Si vous ne saisissez pas de date max, alors la date max sera la date du jour lors de l'analyse",
            minimum: 'Minimum',
            maximum: 'Maximum',
            switch: 'Oui / Non',
            dropdownSingle: 'Liste à choix unique',
            dropdownMultiple: 'Liste à choix multiple',
            range: 'Intervalle de nombres',
            integer: 'Nombre entier',
            float: 'Nombre décimal',
            date: 'Date',
            text: 'Texte',
            url: 'URL',
            unit: 'Unité',
            days: 'jours',
            months: 'mois',
            years: 'ans',
            choices: 'Choix liste',
            warningChoices: `Le choix -1 est implicite, il est inutile de le saisir ici, il sera ajouté
            automatiquement dans la page d'analyse.<br />
            <br />
            Les choix seront ordonnés automatiquement par ordre croissant de code à la sauvegarde.<br />
            <br />
            Exemple :<br />
            <pre>
1: Choix 1
2: Choix 2</pre>`,
            section: 'Section',
            warningSection: `Si vous souhaitez faire apparaître les sections dans un ordre particulier, vous
            pouvez les préfixer par une lettre<br />
            Exemple :<br />
            <pre>A - Généralités</pre>`,
            help: 'Aide',
            required: 'Obligatoire',
            rules: 'Règles',
            errors: {
                required: 'Le critère #criterion# doit être rempli',
                range: 'Le critère #criterion# doit être compris entre #min# et #max#',
                list: 'Le critère #criterion# doit appartenir à #choices#',
                url: 'Le critère #criterion# contient une URL invalide',
                combinations: 'Le critère #criterion# ne respecte pas les combinaisons autorisées #combinations#',
                from: 'de',
                to: 'à',
                or: 'ou',
                rules: {
                    si_contient_alors_different:
                        'Si le critère #criterion# contient #value#, alors le critère #criterion# doit être différent de #value#',
                    si_contient_alors_egal:
                        'Si le critère #criterion# contient #value#, alors le critère #criterion# doit être égal à #value#',
                    si_contient_alors_rempli:
                        'Si le critère #criterion# contient #value#, alors le critère #criterion# doit être rempli',
                    si_different_alors_contient:
                        'Si le critère #criterion# est différent de #value#, alors le critère #criterion# doit contenir #value#',
                    si_different_alors_egal:
                        'Si le critère #criterion# est différent de #value#, alors le critère #criterion# doit être égal à #value#',
                    si_egal_alors_contient:
                        'Si le critère #criterion# est égal à #value#, alors le critère #criterion# doit contenir #value#',
                    si_egal_alors_different:
                        'Si le critère #criterion# est égal à #value#, alors le critère #criterion# doit être différent de #value#',
                    si_egal_alors_egal:
                        'Si le critère #criterion# est égal à #value#, alors le critère #criterion# doit être égal à #value#',
                    si_egal_alors_rempli:
                        'Si le critère #criterion# est égal à #value#, alors le critère #criterion# doit être rempli',
                    si_egal_alors_vide:
                        'Si le critère #criterion# est égal à #value#, alors le critère #criterion# doit être vide',
                    si_rempli_alors_rempli:
                        'Si le critère #criterion# est rempli, alors le critère #criterion# doit être rempli',
                    si_rempli_alors_superieur:
                        'Si le critère #criterion# est rempli, alors le critère #criterion# doit être supérieur',
                    si_vide_alors_rempli:
                        'Si le critère #criterion# est vide, alors le critère #criterion# doit être rempli',
                },
            },
            newRule: 'Nouvelle règle',
            ruleExample: `Exemple :<br />
            <pre>0;3</pre>
            Si plusieurs valeurs, alors il faut les séparer par des points virgules`,
            combinations: 'Combinaisons',
            combinationsWarning: 'Au moins une des combinaisons suivantes devra être respectée',
            orOnly: 'ou uniquement',
            combinationExemple: `Exemple :<br />
            <pre>0;3</pre>
            Si plusieurs valeurs, alors il faut les séparer par des points virgules`,
            minValue: 'Valeur min',
            maxValue: 'Valeur max',
            newCombination: 'Nouvelle combinaison',
            litigation: 'Contentieux',
            jpControl: 'Contrôle JP',
            example: 'Donnée exemple',
            quickEdit: 'Edition rapide',
            aiConfig: 'Configuration moteurs',
            noiseIntensity: 'Intensité bruit',
            lowerThreshold: 'Seuil bas bruit',
            upperThreshold: 'Seuil haut bruit',
            monotony: 'Monotonie',
            monotoneAugmentationAmplitude: 'Amplitude',
            monotoneAugmentationAmplitudeHelp: `Augmentation ou diminution maximale de la valeur du critère :<br>
                en valeur absolue s’il doit s’agir d’un entier<br>
                ou de façon relative s’il doit s’agir d’un pourcentage<br>
                (ex IPLSCRS : le nombre de salarié peut être augmenté de 10% (donc amplitude = 0.10) lors de la génération de décisions tandis que l’âge peut être augmenté de 2 (donc amplitude = 2.00) lors de la génération de décisions)`,
            monotoneAugmentationMin: 'Plancher',
            monotoneAugmentationMinHelp: 'Valeur minimale lors de la génération de décisions',
            monotoneAugmentationMax: 'Plafond',
            monotoneAugmentationMaxHelp: 'Valeur maximale lors de la génération de décisions',
            notConsidered: 'Non pris en compte',
            descending: 'Décroissant',
            neutral: 'Neutre',
            ascending: 'Croissant',
            request: 'Demande',
            proposal: 'Proposition',
            submit: 'Enregistrer',
            submitAndNew: 'Enregistrer et créer un nouveau',
            invalidFormat: 'Format invalide',
        },
    },
};

export const guessLocale = (rawRequestedLocale: string): AppLocale => {
    const defaultLocale: AppLocale = 'en';
    const requestedLocale = rawRequestedLocale.substring(0, 2);
    const locale: AppLocale = requestedLocale in appTranslations ? (requestedLocale as AppLocale) : defaultLocale;

    return locale;
};

export const getTranslationsByLocale = (locale: AppLocale): Record<string, any> => appTranslations[locale];

export default appTranslations;
