import HttpClient from '@/common/Http';
import { Criterion } from '@/Litigation/criterion.entity';

export default class CriterionService {
    private httpClient: HttpClient;

    constructor() {
        this.httpClient = new HttpClient('criteria');
    }

    async find(criterionId: string): Promise<Criterion> {
        return await this.httpClient.get<Criterion>(`/criteria/${criterionId}`);
    }

    async findAll(litigationId: string): Promise<Criterion[]> {
        return await this.httpClient.get<Criterion[]>(`/criteria?litigationId=${litigationId}&onlyChildren=true`);
    }

    public create(litigationId: string, criterion: Criterion): Promise<Criterion> {
        return this.httpClient.post<Criterion>(`/litigations/${litigationId}/criteria`, criterion);
    }

    public update(criterionId: string, criterion: Criterion): Promise<Criterion> {
        return this.httpClient.put<Criterion>(`/criteria/${criterionId}`, criterion);
    }

    public move(criterionId: string, position: 'before' | 'after', targetCriterionId: string): Promise<Criterion> {
        return this.httpClient.put<Criterion>(`/criteria/${criterionId}/sort-index`, {
            position,
            targetCriterionId,
        });
    }

    public addForemost(criterionId: string): Promise<Criterion> {
        return this.httpClient.post<Criterion>(`/criteria/${criterionId}/foremost`);
    }

    public removeForemost(criterionId: string): Promise<Criterion> {
        return this.httpClient.delete<Criterion>(`/criteria/${criterionId}/foremost`);
    }
}
