import { Analysis } from '@/Analysis/analysis.entity';
import HttpClient from '@/common/Http';
import { Entry, EntrySelection } from '@/Entry/entry.entity';
import { Criterion } from '@/Litigation/criterion.entity';

export default class EntryService {
    private httpClient: HttpClient;

    constructor() {
        this.httpClient = new HttpClient('entries');
    }

    async setValue(
        analysis: Analysis,
        criterion: Criterion,
        occurrence: Number,
        value: Array<string>,
    ): Promise<Entry | null> {
        return this.httpClient.post<Entry>(
            `/analyses/${analysis.analysisId}/criteria/${criterion.criterionId}/occurrence/${occurrence}`,
            {
                value,
            },
        );
    }

    async addSelection(
        analysis: Analysis,
        criterion: Criterion,
        occurrence: Number,
        selection: EntrySelection,
    ): Promise<Entry | null> {
        return this.httpClient.post<Entry>(
            `/analyses/${analysis.analysisId}/criteria/${criterion.criterionId}/occurrence/${occurrence}/selections`,
            {
                selection,
            },
        );
    }

    async removeSelection(
        analysis: Analysis,
        criterion: Criterion,
        occurrence: Number,
        selection: EntrySelection,
    ): Promise<Entry | null> {
        return this.httpClient.delete<Entry>(
            `/analyses/${analysis.analysisId}/criteria/${criterion.criterionId}/occurrence/${occurrence}/selections/${selection.startAt}-${selection.endAt}`,
        );
    }
}
