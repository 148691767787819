import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

import { useMemberStore } from '@/stores/member';
import AnalysesList from '@/views/AnalysesList.vue';
import AnalysisForm from '@/views/AnalysisForm.vue';
import LitigationForm from '@/views/LitigationForm.vue';
import LitigationList from '@/views/LitigationList.vue';
import Login from '@/views/Login.vue';
import MemberForm from '@/views/MemberForm.vue';
import MemberList from '@/views/MemberList.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'login',
        component: Login,
    },
    {
        path: '/home',
        name: 'litigation-list',
        component: LitigationList,
    },
    {
        path: '/litigation-:litigationId',
        name: 'litigation-form',
        component: LitigationForm,
        props: true,
    },
    {
        path: '/litigation-:litigationId/analyses',
        name: 'analysis-list',
        component: AnalysesList,
        props: true,
    },
    {
        path: '/analysis-:analysisId',
        name: 'analysis-form',
        component: AnalysisForm,
        props: true,
    },
    {
        path: '/members',
        name: 'member-list',
        component: MemberList,
    },
    {
        path: '/member-:memberId',
        name: 'member-form',
        component: MemberForm,
        props: true,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const memberStore = useMemberStore();

    if (memberStore.isLoggedIn && to.name === 'login') {
        next({ name: 'litigation-list' });
    } else if (!memberStore.isLoggedIn && to.name !== 'login') {
        next({ name: 'login', query: { redirect: to.path } });
    } else {
        next();
    }
});

export default router;
