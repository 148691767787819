import HttpClient from '@/common/Http';
import { Litigation } from '@/Litigation/litigation.entity';

export default class LitigationService {
    private httpClient: HttpClient;

    constructor() {
        this.httpClient = new HttpClient('litigations');
    }

    async find(litigationId: string): Promise<Litigation> {
        return this.httpClient.get<Litigation>(`/litigations/${litigationId}`);
    }

    async findAll(): Promise<Litigation[]> {
        return this.httpClient.get<Litigation[]>('/litigations');
    }

    public create(name: string): Promise<Litigation> {
        return this.httpClient.post<Litigation>('/litigations', { name });
    }

    public update(litigationId: string, litigation: Litigation): Promise<Litigation> {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { criteria, ...litigationWithoutCriteria } = litigation;

        return this.httpClient.put<Litigation>(`/litigations/${litigationId}`, litigationWithoutCriteria);
    }
}
