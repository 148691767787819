import { Litigation } from '@/Litigation/litigation.entity';

export enum MemberRole {
    ADMIN = 'admin',
    SUPERVISOR = 'supervisor',
    USER = 'user',
}

export type Member = {
    memberId?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    role?: MemberRole;
    timeZone?: string;
    litigations?: Array<Litigation>;
    isEnabled?: boolean;
};
