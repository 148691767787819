import HttpClient from '@/common/Http';

type Status = { apiRelease: string; checkedAt: Date };

export default class StatusService {
    private httpClient: HttpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    async getStatus(): Promise<Status> {
        return this.httpClient.get<Status>('/status', { showLoader: false });
    }
}
