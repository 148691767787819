import { defineStore } from 'pinia';

type LoaderState = { requestsPending: number };

export const useLoaderStore = defineStore('loader', {
    state: (): LoaderState => ({
        requestsPending: 0,
    }),
    getters: {
        isLoading(state: LoaderState): boolean {
            return state.requestsPending > 0;
        },
    },
    actions: {
        pending() {
            this.requestsPending += 1;
        },
        done() {
            this.requestsPending -= 1;
            this.requestsPending = Math.max(0, this.requestsPending);
        },
    },
});
